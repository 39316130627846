export default {
    /**
     * 根据key获取对应的cookie
     * @param {*} key
     */
    get(key) {
        const arrCookie = document.cookie.split("; ");
        for (let i = 0; i < arrCookie.length; i++) {
            const arr = arrCookie[i].split("=");
            if (key == arr[0]) {
                return arr[1];
            }
        }
        return false;
    },
    /**
     * 设置一个cookie
     * @param {*} key
     * @param {*} value
     * @param {*} time 存储时间 可使用单位 s(秒)，m(分)，h(小时)，d(天)  
     * @param {*} timeType 存储时间的类型  max-age 或 expires
     */
    set(key, value, time, timeType = "max-age") {
        let cookieStr = `${key}=${value}`;
        switch (timeType) {
            case "max-age":
                const sec = getSecond(time);
                cookieStr += `; max-age=${sec}`
                break;

            case "expires":
                cookieStr += `; expires=${time}`;
                break;
        }
        document.cookie = cookieStr;
    },
    /**
     * 根据key删除对应的cookie
     * @param {*} key 
     */
    remove(key) {
        document.cookie = `${key}=v; max-age=-1`;
    },
};

/**
 * 根据单位转换成秒
 * @param {*} time
 */
function getSecond(time) {
    const unit = time.slice(-1);
    const t = time.slice(0, time.length - 1);
    switch (unit) {
        case "s":
            return t;
        case "m":
            return t * 60;
        case "h":
            return t * 60 * 60;
        case "d":
            return t * 24 * 60 * 60;
    }
}