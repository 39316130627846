<template>
    <div class="exchange-code">
        <van-form @submit="onSubmit" autocomplete="off">
            <van-field
                v-model.trim="code"
                type="text"
                name="code"
                placeholder="请输入兑换码"
            />
            <div class="desc">
                <div><strong>·</strong> 每个兑换码仅可使用一次</div>
                <div><strong>·</strong> 兑换码使用后将与您的微信号绑定</div>
            </div>
            <div style="margin-top: 40px;">
                <van-button round block type="info" native-type="submit">
                    立即兑换
                </van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
import cookie from "@/utils/manageCookie";
export default {
    name: "ExchangeCode",
    data: () => ({
        code: "",
        count: 0,
        maxErrorCount: 5,
    }),
    methods: {
        async onSubmit(value) {
            // MtaH5.clickStat("codeclick")
             window._czc.push(['_trackEvent','兑换码页面', '点击兑换'])
            if (this.count >= this.maxErrorCount) {
                this.$toast("尝试次数太多，请稍后再试。");
                return;
            } else {
                const code = this.code;
                if (!code) {
                    this.$toast("请输入兑换码");
                    return;
                }
                
                const res = await axios.get("/code_exchange", {
                    params: {
                        rep_code: code
                    }
                })
                if (res.code == 1) {
                    this.$toast("兑换成功");
                    this.$router.push("/my");
                } else if (res.code == 0) {
                    this.$toast(res.message);
                    this.manageCount();
                }
            }
        },
        manageCount() {
            this.count++; 
            const errorTime = cookie.get("exchangeErrorTime");
            if (errorTime) {
                cookie.set("exchangeCount", this.count, errorTime, "expires");
            } else {
                const date = new Date();
                const nowMin = date.getMinutes()
                date.setMinutes(nowMin + 10)
                date.toGMTString()
                const time = date.toGMTString();
                cookie.set("exchangeCount", this.count, time, "expires");
                cookie.set("exchangeErrorTime", time, time, "expires");
            }
        }
    },
    beforeRouteEnter(to, from, next){
        let count = cookie.get("exchangeCount");
        if (count) {
            count = parseInt(count.replace(/\D/g, "").slice(0, 1));
            if (!count) {
                count = 0;
            }
            next(vm => {	
                vm.count = count;
            })
        } else {
            next();
        }
    },
};
</script>

<style lang="less" scoped>
.exchange-code {
    display: flex;
    justify-content: center;
    padding-top: 25%;

    .van-form {
        width: 80%;
        
        .van-cell {
            padding: 8px;

            &::after {
                left: 0;
                right: 0;
                border-color: #d4d4d4;
            }
        }
    }
    .desc {
        margin-top: 20px;
        font-size: 14px;
        line-height: 2;
    }
}
</style>
